import './Modal.css';

const Modal = ({ title, onClose, children }) => {
  return (
    <div className="ModalOverlay" onClick={onClose}>
      <div className="Modal" onClick={e => e.stopPropagation()} role="dialog">
        <div className="ModalHeader">
          {onClose && (<button type="button" className="ModalClose" onClick={onClose}>×</button>)}
          {title && (<div className="ModalTitle">{title}</div>)}
        </div>
        <div className="ModalContent">
          {children}
        </div>
      </div>
    </div>
  );
};

export default Modal;

import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { hide } from '../../../state/notifySlice';

import './Notifications.css';

function Notification({ item }) {
  const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => dispatch(hide(item)), 5000);
  }, [dispatch]);

  return (
    <div
      role="alert"
      onClick={() => dispatch(hide(item))}
      className={`Notification button${item.type ? ' ' + item.type : ''}`}
    >
      {item.text}
    </div>
  );
}

function Notifications() {
  const messages = useSelector((state) => state.notify.messages);

  return messages.length > 0 && (
    <div className="Notifications">
      {messages.map(item => <Notification key={item.id} item={item} />)}
    </div>
  );
}

export default Notifications;

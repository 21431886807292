import { createSlice } from '@reduxjs/toolkit';

const fromLocalStorage = localStorage.getItem('sections');
const parsed = fromLocalStorage ? JSON.parse(fromLocalStorage) : {};

export const sectionsSlice = createSlice({
  name: 'sections',
  initialState: {
    fetching: false,
    sections: parsed,
  },
  reducers: {
    setItems: (state, action) => {
      const items = action.payload;
      if (items) {
        const keyValue = {};
        items.forEach(item => {
          const { Title, Content } = item;
          keyValue[Title] = Content;
        });
        state.sections = keyValue;
        const json = JSON.stringify(keyValue);
        localStorage.setItem('sections', json);
      }
    },
    setFetching: (state, action) => {
      state.fetching = action.payload || false;
    },
  },
});

export const { setItems: setSections, setFetching: setSectionsFetching } = sectionsSlice.actions;

export default sectionsSlice.reducer;

import './Container.css';

function Container({ children, className = '', ...props }) {
  const classes = 'Container' + (className ? ' ' + className : '');
  return children && (
    <div className={classes} {...props}>
      {children}
    </div>
  );
}

export default Container;

import { createSlice } from '@reduxjs/toolkit';

export const workingTimeSlice = createSlice({
  name: 'workingTime',
  initialState: {
    isWorking: true
  },
  reducers: {
    setIsWorking: (state, action) => {
      state.isWorking = action.payload;
    },
  },
});

export const { setIsWorking } = workingTimeSlice.actions;

export default workingTimeSlice.reducer;

import { createSlice } from '@reduxjs/toolkit';

const fromLocalStorage = localStorage.getItem('options');
const parsed = fromLocalStorage ? JSON.parse(fromLocalStorage) : [];

export const optionsSlice = createSlice({
  name: 'options',
  initialState: {
    all: parsed,
    fetching: false,
    selected: {},
    currentProduct: null,
    currentProductVariants: null,
  },
  reducers: {
    setCurrentProduct: (state, action) => {
      const { product, variants } = action.payload;
      state.currentProduct = product || null;
      state.currentProductVariants = variants || null;
    },
    setSelectedOptions: (state, action) => {
      const { productId, selected } = action.payload;
      state.selected[productId] = selected;
    },
    setAllOptions: (state, action) => {
      const items = action.payload || [];
      state.all = items;
      const json = JSON.stringify(items);
      localStorage.setItem('options', json);
    },
    setFetching: (state, action) => {
      state.fetching = action.payload || false;
    },
  },
});

export const {
  setCurrentProduct,
  setSelectedOptions,
  setAllOptions,
  setFetching: setOptionsFetching
} = optionsSlice.actions;

export default optionsSlice.reducer;

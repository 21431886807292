import { createSlice } from '@reduxjs/toolkit';

const fromLocalStorage = localStorage.getItem('categories');
const parsed = fromLocalStorage ? JSON.parse(fromLocalStorage) : [];

export const categoriesSlice = createSlice({
  name: 'categories',
  initialState: {
    fetching: false,
    categories: parsed,
  },
  reducers: {
    setItems: (state, action) => {
      const items = action.payload;
      if (items) {
        state.categories = items;
        const json = JSON.stringify(items);
        localStorage.setItem('categories', json);
      }
    },
    setFetching: (state, action) => {
      state.fetching = action.payload || false;
    },
  },
});

export const { setItems: setCategories, setFetching: setCategoriesFetching } = categoriesSlice.actions;

export default categoriesSlice.reducer;

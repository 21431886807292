import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { getRecordsWithMeta } from '../../../requests';

import { add as addMessage } from '../../../state/notifySlice';

import Container from '../../elements/Container/Container';
import Fetching from '../../elements/Fetching/Fetching';
import Markdown from '../../elements/Markdown/Markdown';

import './Records.css';

function RecordsListItem({ item, index }) {
  return (
    <article className="RecordSmall Bun space-one appear-seq" style={{ '--animation-order': index }}>
      <Link to={`/info/${item.ID}`} className="RecordSmallTitle">{item.Title}</Link>
      <Markdown className="RecordSmallContent">{item.Content || ''}</Markdown>
    </article>
  );
}

function RecordsList({ items }) {
  return items.length > 0 ? (
    <div className="RecordsList grid">
      {items.map((item, i) => <RecordsListItem key={item.ID} item={item} index={i} />)}
    </div>
  ) : null;
}

function Records() {
  const [fetching, setFetching] = useState(false);
  const [items, setItems] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    let disposed = false;
    setFetching(true);
    getRecordsWithMeta({ type: 'page' }) // TODO: move to requests
      .then(({ data }) => {
        if (!disposed) {
          const records = data || [];
          const sorted = records.sort((a, b) => a.Title > b.Title);
          setItems(sorted);
        }
      })
      .catch(error => {
        console.error(error);
        if (!disposed) dispatch(addMessage({
          type: 'error',
          text: 'Erro ao carregar conteúdo'
        }));
      })
      .finally(() => { if (!disposed) setFetching(false); });
    return () => disposed = true;
  }, [dispatch]);

  return (
  <div className="Records space-two">
    <Container>
      {fetching ? <Fetching /> : <RecordsList items={items} />}
    </Container>
  </div>
  );

}

export default Records;

import { useState } from 'react';

import Section from '../../elements/Section/Section';

import './Promt.css';

const promtHide = localStorage.getItem('promtHide') || false;

const setPromtHide = (v = true) => {
  localStorage.setItem('promtHide', v);
};

function Promt() {
  const [hide, setHide] = useState(!!promtHide); // TODO: global state

  return hide ? null : (
    <div className="Promt">
      <div className="PromtDialog Bun bg-white" role="alertdialog">
        <div className="Bun-content">
          <div className="PromtText">
            <Section name="Agreement Popup" skeleton="Personal Data Agreement" />
          </div>
          <button
            type="button"
            className="PromtAgree"
            tabIndex={1}
            onClick={() => {
              setHide(true);
              setPromtHide();
            }}
          >
            Concordo
          </button>
        </div>
      </div>
    </div>
  );
}

export default Promt;

import { createSlice } from '@reduxjs/toolkit';

export const cartSlice = createSlice({
  name: 'cart',
  initialState: {
    opened: false,
    quantity: 0,
    total: 0,
    items: [],
  },
  reducers: {
    open: (state) => {
      state.opened = true;
    },
    close: (state) => {
      state.opened = false;
    },
    toggle: (state) => {
      state.opened = !state.opened;
    },
    addItem: (state, action) => {
      const item = action.payload;
      const target = state.items.find((i) => {
        const pid = i.product.ID === item.product.ID;
        // if (!id) return false;
        let variants = true;
        Object.keys(i.variants).forEach(v => {
          if (i.variants[v] !== item.variants[v]) variants = false;
        });
        const options = i.options.length === 0 && item.options.length === 0;
        return pid && variants && options;
      });
      if (target) {
        target.quantity = target.quantity + item.quantity;
        target.price = item.price;
      } else {
        item.id = Date.now();
        state.items.push(item);
      }
      state.quantity = state.items.reduce((a, b) => a + b.quantity || 0, 0);
      state.total = state.items.reduce((a, b) => a + b.price * b.quantity || 0, 0);
    },
    changeItemCount: (state, action) => {
      const item = action.payload;
      const target = state.items.find((i) => i.id === item.id);
      if (target) target.quantity = item.quantity;
      state.quantity = state.items.reduce((a, b) => a + b.quantity || 0, 0);
      state.total = state.items.reduce((a, b) => a + b.price * b.quantity || 0, 0);
    },
    removeItem: (state, action) => {
      state.items.splice(action.payload, 1);
      state.quantity = state.items.reduce((a, b) => a + b.quantity || 0, 0);
      state.total = state.items.reduce((a, b) => a + b.price * b.quantity || 0, 0);
    },
    clear: (state) => {
      state.items = [];
      state.quantity = 0;
      state.total = 0;
    },
    save: (state) => {
      const cart = { ...state };
      const toStorage = JSON.stringify(cart);
      localStorage.setItem('cart', toStorage);
    },
    restore: (state) => {
      const fromStorage = localStorage.getItem('cart');
      if (fromStorage) {
        const cart = JSON.parse(fromStorage);
        state.quantity = cart.quantity || state.quantity;
        state.total = cart.total || state.total;
        state.items = cart.items || state.items;
      }
    }
  },
});

export const { open, close, toggle, addItem, changeItemCount, removeItem, clear, save, restore } = cartSlice.actions;

export default cartSlice.reducer;

import { useSelector } from 'react-redux';

import Container from '../../elements/Container/Container';
import Fetching from '../../elements/Fetching/Fetching';
import Markdown from '../../elements/Markdown/Markdown';
import Image from '../../elements/Image/Image';

import './Promos.css';

function Promo({ item, index }) {
  return (
    <div key={item.ID} className="tile tile-3 appear-seq" style={{ '--animation-order': index }}>
      <div className="Promo Bun Bun-big">
        <div className="Bun-image">
          <Image src={item.Image} loading="lazy" />
        </div>
        <div className="Bun-content bg-gradient-left">
          <Markdown>{item.Content}</Markdown>
        </div>
      </div>
    </div>
  );
}

function PromosList({ items }) {
  return items.length > 0 ? (
    <div className="PromosList tiles evenly">
      {items.map((item, i) => <Promo key={item.ID} item={item} index={i} />)}
    </div>
  ) : null;
}

function Promos() {
  const { promos: items, fetching } = useSelector(state => state.promos);

  return (
    <div className="Promos space-two">
      <Container>
        {fetching && items.length === 0 ? <Fetching /> : <PromosList items={items} />}  
      </Container>
    </div>
  );
}

export default Promos;

import axios from 'axios';

import { API_PATH } from './constants';

export const getRecordsWithMeta = ({ parentId = null, type = null } = {}) => 
  axios.get(`${API_PATH}/record/listAllWithMeta/${type}${parentId?`/${parentId}`:''}`);

export const getRecordByID = ({ id }) => axios.get(`${API_PATH}/record/get/${id}`);

export const getOrder = ({ id, key }) => axios.get(`${API_PATH}/order/get/${id}/${key}`);

export const makeOrder = (data) => axios.post(`${API_PATH}/order/make`, data);

export const listGallery = () => axios.get(`${API_PATH}/gallery/list`);

export const getSettings = () => axios.get(`${API_PATH}/settings/all`);

export const getCategories = (parentId) => getRecordsWithMeta({ parentId, type: 'category' });

export const getSections = () => getRecordsWithMeta({ type: 'section' });

export const getPromos = () => getRecordsWithMeta({ type: 'promo' });

export const getProducts = () => getRecordsWithMeta({ type: 'product' });

export const getProductOptions = () => getRecordsWithMeta({ type: 'product-option' });

import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { IS_DEV } from '../../../constants';

import { setIsWorking } from '../../../state/workingTimeSlice';

import Container from '../../elements/Container/Container';
import Section from '../../elements/Section/Section';

import './WorkingTime.css';

const calcWorkingHours = (shedule = {}, lastMinutes = 0) => {
  const now = new Date();
  const wd = now.getDay();
  const wh = now.getHours();
  const workToday = Object.keys(shedule).includes(String(wd));
  const workNow = shedule[wd] && shedule[wd].includes(wh) ;
  const lastHour = shedule[wd] && shedule[wd].length - 1 === shedule[wd].sort((a, b) => a - b).indexOf(wh);
  const workBeforeClose = lastHour && (60 - now.getMinutes()) < Number(lastMinutes);
  if (IS_DEV) console.log({ workToday, workNow, lastHour, workBeforeClose });
  return workToday && workNow && !workBeforeClose;
};

const params = { shedule: {}, lastMinutes: 0 };

function WorkingTime() {
  const { isWorking } = useSelector(state => state.workingTime);
  
  const { settings } = useSelector(state => state.settings);

  const sheduleRaw = settings['Working Hours'] || '';
  try { params.shedule = sheduleRaw ? JSON.parse(sheduleRaw) || {} : {}; }
  catch (e) { console.error(e); }

  params.lastMinutes = settings['Last Minutes'] || 0;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setIsWorking(calcWorkingHours(params.shedule, params.lastMinutes)));
  }, [dispatch, settings]);

  useEffect(() => {
    let disposed = false;

    let checkInterval = setInterval(() => {
      if (!disposed) dispatch(setIsWorking(calcWorkingHours(params.shedule, params.lastMinutes)));
      else clearInterval(checkInterval);
    }, 15 * 1e3);

    return () => { clearInterval(checkInterval); disposed = true; };
  }, []);

  return !isWorking && (
    <div className="WorkingTime space-one">
      <Container>
        <Section name="Working Time" />
      </Container>
    </div>
  );
}

export default WorkingTime;

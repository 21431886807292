import { createSlice } from '@reduxjs/toolkit';

const fromLocalStorage = localStorage.getItem('products');
const parsed = fromLocalStorage ? JSON.parse(fromLocalStorage) : [];

export const productsSlice = createSlice({
  name: 'products',
  initialState: {
    fetching: false,
    items: parsed
  },
  reducers: {
    setItems: (state, action) => {
      const items = action.payload || [];
      state.items = items;
      const json = JSON.stringify(items);
      localStorage.setItem('products', json);
    },
    setFetching: (state, action) => {
      state.fetching = action.payload || false;
    },
  },
});

export const { setItems: setProducts, setFetching: setProductsFetching } = productsSlice.actions;

export default productsSlice.reducer;

import { BASE_PATH, imagePlaceholder } from '../../../constants';

export const imageSrc = (path) => {
    return path ? (path.startsWith('http') ? path : `${BASE_PATH}/${path}`) : imagePlaceholder;
};

const Image = ({ src, alt, ...rest }) => {
    return <img src={imageSrc(src)} alt={alt || ''} {...rest} />;
}

export default Image;

import { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { currency } from '../../../constants';

import { removeItem, close, restore, save, changeItemCount } from '../../../state/cartSlice';

import Image from '../../elements/Image/Image';

import './Cart.css';

function CartItem({ item, index }) {
  const dispatch = useDispatch();

  return (
    <div key={item.id} className="CartItem">
      <Image className="CartItem-image" src={item.product.Image} />
      <div className="CartItem-info">
        <div className="CartItem-title">
          {item.product.Title}
        </div>
        <div className="CartItem-variants">
          {Object.keys(item.variants).map(prop => (<div key={prop}>{prop}: {item.variants[prop]}</div>))}
        </div>
        <div className="CartItem-options">
          {item.options.map(o => (<span key={o.option.ID}>{o.option.Title} &times; {o.quantity}</span>))}
        </div>
        <div className="CartItem-price">
          Preço: <span className="Price">{Math.round(item.price * 100) / 100} {currency}</span> &times; {item.quantity}
        </div>
        <div className="CartItem-quantity">
          <button disabled={item.quantity === 1} type="button" className="ProductQuantitySub" onClick={() => {
            if (item.quantity > 1) {
              dispatch(changeItemCount({ ...item, quantity: item.quantity - 1 }));
              dispatch(save());
            }
          }}>
            -
          </button>
          <span className="ProductQuantityNumber">{item.quantity}</span>
          <button type="button" className="ProductQuantityAdd" onClick={() => {
            dispatch(changeItemCount({ ...item, quantity: item.quantity + 1 }));
            dispatch(save());
          }}>
            +
          </button>
        </div>
      </div>
      <button
        type="button"
        className="CartItem-remove"
        onClick={() => { dispatch(removeItem(index)); dispatch(save()); }}
      >
        &times;
      </button>
    </div>
  );
}

function Cart() {
  const { items, opened, quantity, total } = useSelector(state => state.cart);
  const dispatch = useDispatch();

  const cartCloseRef = useRef(null);
  
  useEffect(() => {
    if (opened && cartCloseRef.current) {
      setTimeout(() => cartCloseRef.current.focus(), 100);
    }
  }, [opened]);

  useEffect(() => {
    dispatch(restore());
  }, []);

  return (
    <div className={`Cart${opened ? ' opened': ' closed'}`}>
      <button ref={cartCloseRef} type="button" className="Cart-close" onClick={() => dispatch(close())}>&times;</button>
      <div className="Cart-title">Carrinho</div>
      <div className="Cart-list">
        {items.length > 0 ? items.map((item, index) => (
          <CartItem key={index} item={item} index={index} />
        )) : (
          <div>
            Carrinho Vazio
          </div>
        )}
      </div>
      {quantity > 0 && (
        <div className="Cart-summary">
          <div>Produtos: {quantity}</div>
          <div>Preço total: <span className="Price">{Math.round(total * 100) / 100} {currency}</span></div>
          <div className="Cart-order">
            <Link className="button Cart-OrderButton" to="/order" onClick={() => dispatch(close())}>
              Pedido
            </Link>
          </div>
        </div>
      )}
    </div>
  );
}

export default Cart;

import './Fetching.css';

const Fetching = () => {
  return (
    <div className="Fetching">
      <span className="icon icon-pizza"></span>
    </div>
  );
}

export default Fetching;

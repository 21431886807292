import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';

import { currency } from '../../../constants';

import { scrollTop } from '../../../helper';

import { toggle } from '../../../state/cartSlice';

import Container from '../../elements/Container/Container';
import Section from '../../elements/Section/Section';

import './Header.css';

// TODO: move to backend
const title = 'PizzaTime';
const menuItems = [
  { title: 'Ementa', path: '/menu' },
  { title: 'Ofertas', path: '/promos' },
  { title: 'Informação', path: '/info' },
  { title: 'Fotogaleria', path: '/gallery' },
  { title: 'Sugestões', path: '/reviews' },
];
const manager = { title: 'Gestor', path: '/manager' };

function MiniCart() {
  const { quantity, total, opened } = useSelector((state) => state.cart);

  const dispatch = useDispatch();

  const [wasOpened, setWasOpened] = useState(false);
  
  const cartOpenRef = useRef(null);
  
  useEffect(() => {
    if (!wasOpened && opened) { setWasOpened(true) }
    if (wasOpened && !opened && cartOpenRef.current) {
      setTimeout(() => cartOpenRef.current.focus(), 100);
    }
  }, [opened, wasOpened]);

  return (
    <div className="MiniCart unshrink">
      <button
        ref={cartOpenRef}
        title="Abrir carrinho"
        className="MiniCart-Button button" onClick={() => dispatch(toggle())}
      >
        <div className="MiniCart-Button-Quantity">{quantity}</div>
        <div className="icon icon-cart-red"></div>
        <div className="MiniCart-Button-Total">{Math.round(total * 100) / 100} {currency}</div>
      </button>
    </div>
  );
}

const ScrollTop = () => {
  const scrollTopRef = useRef();

  useEffect(() => {
    const onScrollEnd = () => {
      console.log(window.scrollY);
      scrollTopRef.current.style.opacity = window.scrollY > 100 ? '1' : '0';
    };
    window.addEventListener('scrollend', onScrollEnd);
    return () => window.removeEventListener('scroll', onScrollEnd);
  }, []);

  return (
    <button
      type="button"
      className="HeaderScrollTop button"
      ref={scrollTopRef}
      onClick={scrollTop}
    >
      Para cima
    </button>
  );
}

function Header({ ...rest }) {
  const [menu, setMenu] = useState(false);

  const { isManager } = useSelector(state => state.settings);

  const items = isManager ? [...menuItems, manager] : menuItems;

  const handleClick = () => {
    setMenu(false);
    scrollTop();
  }

  return (
    <header className="Header">
      <Container className="Header-Body flex between" {...rest}>
        <Link className="Header-Logo unshrink" to="/" title="To Main Page" role="button">
          <img src="/images/logo-90.png" alt={title} importance="high" />
        </Link>
        <div className={`Header-Content${menu ? '' : ' hide-narrow'}`}>
          <div className="Header-Info hide-medium hide-narrow flex evenly">
            <Section name="Header - Column 1" />
            <Section name="Header - Column 2" />
            <Section name="Header - Column 3" />
            <Section name="Header - Column 4" />
            <Section name="Header - Column 5" />
          </div>
          <ul className={`Header-Menu menu-inline${menu ? '' : ' hide-narrow'}`} role="navigation">
            {items.map(item => (
              <li key={item.path}>
                <NavLink
                  to={item.path}
                  className={({ isActive }) => ["button", (isActive?'active':undefined)].join(' ')}
                  onClick={handleClick}
                >
                  {item.title}
                </NavLink>
              </li>
            ))}
          </ul>
        </div>
        <div className="grow hide-medium hide-wide">
          <button
            type="button"
            className={`MenuButton button${menu?' opened':''}`}
            onClick={e => setMenu(s => !s)}
            title="Main Menu"
          >
            <span></span>
            <span></span>
            <span></span>
          </button>
          <ScrollTop />
        </div>
        <MiniCart />
      </Container>
    </header>
  );
}

export default Header;

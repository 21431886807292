import { useId, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { managerNumber as mn } from "../../../constants";

import { setManager } from "../../../state/settingsSlice";
import { add as addMessage } from '../../../state/notifySlice';

import Container from "../../elements/Container/Container";

import "./Manager.css";



const getP = (x) => {
  const date = new Date();
  const m = 1 + date.getMonth();
  const d = date.getDate();
  const n = x * Number(`${m}${d<10?`0${d}`:d}`);
  return `${n<1000?'0':''}${n}`;
  // return `${p}${m<10?`0${m}`:m}${d<10?`0${d}`:d}`;
};

function Manager() {
  const inputId = useId();

  const [pressCount, setPressCount] = useState(0);
  const [value, setValue] = useState('');

  const { isManager } = useSelector(state => state.settings);

  const dispatch = useDispatch();

  const showFields = pressCount >= mn;

  const handleClick = () => {
    setPressCount(pressCount + 1);
  }

  const handleEnable = () => {
    const p = getP(mn);
    if (value === p) {
      dispatch(setManager(true));
      dispatch(addMessage({
        type: 'info',
        text: 'Modo gestor ativado'
      }));
    } else {
      setPressCount(0);
      setValue('');
    }
  }

  const handleDisable = () => {
    setValue('');
    setPressCount(0);
    dispatch(setManager(false));
    dispatch(addMessage({
      type: 'info',
      text: 'Modo gestor desativado'
    }));
  }

  const handleChange = (event) => {
    setValue(event.target.value);
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleEnable();
    }
  }

  return (
    <div className="Manager space-two">
      <Container> 
        <h2 onClick={handleClick}>
          Página do gerente
        </h2>
        {isManager ? (
          <div>
            <p>You are in manager mode</p>
            <button
              type="button"
              className="button Manager-button"
              onClick={handleDisable}
            >
              Desativar modo gestor
            </button>
          </div>
        ) : (showFields && (
          <div>
            <div className="space-one">
              <label className="input-label" htmlFor={inputId}>
                Por favor insira o código
              </label>
              <br />
              <input
                type="text"
                id={inputId}
                className="input-field"
                value={value}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                placeholder="0000"
              />
            </div>
            <div>
              <button
                type="button"
                className="button Manager-button"
                onClick={handleEnable}
                disabled={!value}
              >
                Ativar modo gerenciador
              </button>
            </div>
          </div>
        ))}
      </Container>
    </div>
  );
}

export default Manager;

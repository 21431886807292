export const IS_DEV = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

export const ORIGIN = window.location.origin;

export const BASE_PATH = IS_DEV ? 'http://localhost:8000' : ORIGIN + '/backend';

export const API_PATH = BASE_PATH + '/api';

export const currency = '€'; // TODO: move to settings

export const imagePlaceholder = 'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=';

export const managerNumber = 7;

export const developerName = 'Alexei Gaiduk';
export const developerEmail = 'le5h@yandex.ru';

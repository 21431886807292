import React, { useSelector } from 'react-redux';

import Markdown from '../Markdown/Markdown';

function Section({ name, skeleton = '', ...rest }) {
    const { sections, fetching } = useSelector(state => state.sections);
    const text = sections[name] || '';

    return !text && fetching ? skeleton : <Markdown {...rest}>{text}</Markdown>;
}

export default Section;

function compare(a, b) {
  let equal = true;
  [...Object.keys(a), ...Object.keys(b)].forEach(k => {
    if (a[k] !== b[k]) {
      equal = typeof a[k] === 'object' && typeof b[k] === 'object' ? compare(a[k], b[k]) : false;
    }
  });
  return equal;
};

// test: console.log('compare', compare({ a: { b: 2 } }, { a: { b: 2 } }));

function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

function translate(langPack, lang) {
  return (text) => langPack[text] && langPack[text][lang] ? langPack[text][lang] : text;
}

function scrollTop() {
  window.scrollTo({ top: 0, behavior: 'smooth' });
}

export { compare, shuffleArray, translate, scrollTop };

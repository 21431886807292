import { useSelector } from 'react-redux';

// TODO: head
const CustomStyle = () => {
    const { settings } = useSelector(state => state.settings);
    const style = settings['Custom Style'] || '';
    return <style>{style}</style>;
}

export default CustomStyle;

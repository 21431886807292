import { createSlice } from '@reduxjs/toolkit';

export const variantsSlice = createSlice({
  name: 'variants',
  initialState: {
    variants: {},
    current: null,
  },
  reducers: {
    setCurrent: (state, action) => {
      state.current = action.payload;
    },
    setVariants: (state, action) => {
      const { productId, selected } = action.payload;
      state.variants[productId] = selected;
    },
  },
});

export const { setCurrent, setVariants } = variantsSlice.actions;

export default variantsSlice.reducer;

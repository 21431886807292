import { createSlice } from '@reduxjs/toolkit';

let counter = 0;

export const notifySlice = createSlice({
  name: 'notify',
  initialState: {
    messages: [],
  },
  reducers: {
    add: (state, action) => {
      counter += 1;
      action.payload['id'] = counter;
      action.payload['hidden'] = false;
      action.payload['timestamp'] = Date.now();
      state.messages.push(action.payload);
    },
    hide: (state, action) => {
      const id = action.payload.id;
      const item = state.messages.find(i => i.id === id)
      const index = state.messages.indexOf(item);
      state.messages.splice(index, 1);
    },
    wipe: (state) => {
      state.messages = [];
    }
  },
});

export const { add, hide, wipe } = notifySlice.actions;

export default notifySlice.reducer;

import { createSlice } from '@reduxjs/toolkit';

const fromLocalStorage = localStorage.getItem('promos');
const parsed = fromLocalStorage ? JSON.parse(fromLocalStorage) : [];

export const promosSlice = createSlice({
  name: 'promos',
  initialState: {
    fetching: false,
    promos: parsed, // all the promos
    available: {}, // { [promoId]: count } // available products count for promo
    selectedProducts: {}, // { [promoId]: { [index]: { product, variants } } } // selected products for promo
    // usedCartItems: {}, // { [cartItemId]: { promoId, count } }
    selectIndex: null, // current product index for promo
    selectPromoId: null, // current promo id
    selectProducts: [], // list of products for current promo
    selectVariant: {}, // current product index for promo
  },
  reducers: {
    setFetching: (state, action) => {
      state.fetching = action.payload || false;
    },
    setItems: (state, action) => {
      const items = action.payload || [];
      state.promos = items;
      const json = JSON.stringify(items);
      localStorage.setItem('promos', json);
    },
    setPromoAvailable: (state, action) => {
      const { id, count } = action.payload || {};
      if (id) {
        if (!state.selectedProducts[id]) state.selectedProducts[id] = {};
        if (count < state.available[id]) {
          Object.keys(state.selectedProducts[id]).forEach(i => {
            if (Number(i) + 1 > count) delete state.selectedProducts[id][i];
          });
        }
        state.available[id] = count ? count : 0;
      }
    },
    setSelectedProducts: (state, action) => {
      const { promoId, index, product, variants } = action.payload || {};
      const indexed = state.selectedProducts[promoId] || {};
      if (promoId) state.selectedProducts[promoId] = { ...indexed, [index]: { product, variants } };
      // TODO: check index meets available count
    },
    clearSelectedProducts: (state, _action) => {
      state.selectedProducts = {};
    },
    /*
    setUsedCartItems: (state, action) => {
      const { id, cartItems } = action.payload || {};
      if (id) cartItems.forEach(item => {
        state.usedCartItems[item.id] = {
          count: item.count,
          promoId: id,
          index: item.index,
        };
      });
    },
    */
    setPromoProducts: (state, action) => {
      const { index, promoId, products, variant } = action.payload || {};
      state.selectIndex = index !== undefined ? index : null;
      state.selectPromoId = promoId || null;
      state.selectProducts = products || [];
      state.selectVariant = variant || {};
    },
  },
});

export const {
  setItems: setPromos,
  setFetching: setPromosFetching,
  setPromoAvailable,
  setSelectedProducts,
  // setUsedCartItems,
  setPromoProducts,
  clearSelectedProducts,
} = promosSlice.actions;

export default promosSlice.reducer;
